import React, { lazy } from 'react'

const EDocument = lazy(() =>
  import('./EDocumentLisings').then(module => ({
    default: module.EDocumentList,
  }))
)

const EDocumentForm = lazy(() =>
  import('./EDocumentForm').then(module => ({
    default: module.EDocumentForm,
  }))
)

const ConfirmationLetterListings = lazy(() =>
  import('./ConfirmationLetterListings').then(module => ({
    default: module.ConfirmationLetterListings,
  }))
)

const EDocumentEditForm = lazy(() =>
  import('./EDocumentEditForm').then(module => ({
    default: module.EDocumentEditForm,
  }))
)

const eDocumentRoutes = [
  {
    props: { exact: true, path: '/settings/edocumentsetup' },
    component: <EDocument />,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/registrationcard' },
    component: <EDocumentForm mode={"RegCard"} />,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/confirmationletter/add' },
    component: <EDocumentForm mode={"Confirmation"} />,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/confirmationletter/listings' },
    component: <ConfirmationLetterListings />,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/confirmationletter/edit' },
    component: <EDocumentEditForm mode={"Confirmation"}/>,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/proformainvoice' },
    component: <EDocumentForm mode={"Proforma"} />,
  },
  
]

export default eDocumentRoutes